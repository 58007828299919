import React, { useCallback, useState } from "react";
import { Center, VStack } from "@chakra-ui/react";
import { User } from "@bitsacco/types";
import { SESSION_USER_KEY, getSessionValue } from "../services";
import {
  AuthExperience,
  ResumeStack,
  SignupStack,
  LoginStack,
  RecoverStack,
} from "../components";

export const Auth = React.memo(function Auth(): JSX.Element {
  const [sessionUser] = useState<string>(getSessionValue(SESSION_USER_KEY));
  const [phone, setPhone] = useState<string>("");
  const [authExperience, setAuthExperience] = useState<AuthExperience>(
    sessionUser ? AuthExperience.Resume : AuthExperience.Login,
  );

  return (
    <ControlledAuth
      {...{ phone, setPhone, authExperience, setAuthExperience }}
    />
  );
});

interface ControlledAuthProps {
  phone: string;
  setPhone: (phone: string) => void;
  authExperience: AuthExperience;
  setAuthExperience: (exp: AuthExperience) => void;
}

export const ControlledAuth = React.memo(function ControlledAuth({
  phone,
  setPhone,
  authExperience,
  setAuthExperience,
}: ControlledAuthProps): JSX.Element {
  const [sessionUser] = useState<string>(getSessionValue(SESSION_USER_KEY));
  const [pin, setPin] = useState<string>("");

  const [useNpub, setUseNpub] = useState<boolean>(false);
  const [npub, setNpub] = useState<string>("");

  const [authError, setAuthError] = useState<string>("");

  const updateAuthExperiece = useCallback(
    (exp: AuthExperience) => {
      setAuthError("");
      setPin("");
      setAuthExperience(exp);
    },
    [setAuthError, setPin, setAuthExperience],
  );

  const getAuthExperience = useCallback(() => {
    switch (authExperience) {
      case AuthExperience.Resume:
        try {
          const user: User = JSON.parse(sessionUser);
          return (
            <ResumeStack user={user} setAuthExperience={updateAuthExperiece} />
          );
        } catch (e) {
          console.error(e);
          updateAuthExperiece(AuthExperience.Login);
          return (
            <LoginStack
              pin={pin}
              phone={phone}
              useNpub={useNpub}
              npub={npub}
              authError={authError}
              setPin={setPin}
              setPhone={setPhone}
              setNpub={setNpub}
              setAuthError={setAuthError}
              setAuthExperience={updateAuthExperiece}
              setUseNpub={setUseNpub}
            />
          );
        }
      case AuthExperience.Login:
        return (
          <LoginStack
            pin={pin}
            phone={phone}
            useNpub={useNpub}
            npub={npub}
            authError={authError}
            setPin={setPin}
            setPhone={setPhone}
            setNpub={setNpub}
            setAuthError={setAuthError}
            setAuthExperience={updateAuthExperiece}
            setUseNpub={setUseNpub}
          />
        );
      case AuthExperience.Signup:
        return (
          <SignupStack
            pin={pin}
            phone={phone}
            useNpub={useNpub}
            npub={npub}
            authError={authError}
            setPin={setPin}
            setPhone={setPhone}
            setNpub={setNpub}
            setAuthError={setAuthError}
            setAuthExperience={updateAuthExperiece}
            setUseNpub={setUseNpub}
          />
        );
      case AuthExperience.Recover:
        return (
          <RecoverStack
            pin={pin}
            phone={phone}
            useNpub={useNpub}
            npub={npub}
            authError={authError}
            setPin={setPin}
            setPhone={setPhone}
            setNpub={setNpub}
            setAuthError={setAuthError}
            setAuthExperience={updateAuthExperiece}
            setUseNpub={setUseNpub}
          />
        );
    }
  }, [
    authExperience,
    sessionUser,
    pin,
    phone,
    useNpub,
    npub,
    authError,
    setPin,
    setPhone,
    setNpub,
    setUseNpub,
    setAuthError,
    updateAuthExperiece,
  ]);

  return (
    <Center pt="10">
      <VStack>{getAuthExperience()}</VStack>
    </Center>
  );
});
