import React, { useCallback } from "react";
import {
  DepositFundsRequest,
  OnrampSwapSource,
  SharesTxStatus,
  SharesTxUpdates,
  SolowalletTx,
  TransactionStatus,
  TransactionType,
  TxPath,
  UserTxsRequest,
  UserTxsResponse,
} from "@bitsacco/types";

import { BS_API_GATEWAY_URL, POLL_TIMEOUT_MS } from "../../configs";
import { digitizePhone, fetcher } from "../../utils";
import { StatusToTransactionState } from "../TransactionState";
import { ActiveTx, TransactionTarget } from "../transactions";
import { TransactionModal } from "./TransactionModal";

export interface SharesTxModalProps {
  sharesTxValue: number;
  txTarget: TransactionTarget;
  isOpen: boolean;
  onClose: () => void;
  updateSharesTx: (updates: SharesTxUpdates) => void;
}

const BITSACCO_INTERNAL_USER_ID = "b6287a8d-ff0b-4b98-8a7e-3aab8aef3995";

export const SharesTxModal = React.memo(function SharesTxModal({
  sharesTxValue,
  txTarget,
  isOpen,
  onClose,
  updateSharesTx,
}: SharesTxModalProps): JSX.Element {
  const validate = ({
    txId,
    ledger,
  }: Partial<UserTxsResponse>): SolowalletTx => {
    if (!ledger?.transactions || ledger.transactions.length < 1) {
      throw new Error("Failed to create a deposit transaction");
    }

    const tx = ledger.transactions.find((tx) => tx.id === txId);

    if (!tx) {
      throw new Error("Failed to create a deposit transaction");
    }

    return tx;
  };

  const createTx = useCallback(
    (path: TxPath, amount: number, phone?: string) => {
      return (async (): Promise<ActiveTx> => {
        try {
          const p = phone || txTarget.user.phone || "";
          const onramp: OnrampSwapSource | undefined =
            path === TxPath.Mpesa
              ? {
                  currency: "KES" as unknown as any,
                  origin: {
                    phone: (p && digitizePhone(p, true, true)) || "",
                  },
                }
              : undefined;

          const res = await fetcher<UserTxsResponse, DepositFundsRequest>(
            `${BS_API_GATEWAY_URL}/solowallet/deposit`,
            "POST",
            {
              userId: BITSACCO_INTERNAL_USER_ID,
              amountFiat: amount,
              reference: "bitsacco solowallet",
              onramp,
            },
          );

          const tx = validate(res);

          return {
            path,
            id: tx.id,
            type: tx.type,
            lightning: tx.lightning,
            state: StatusToTransactionState(tx.status),
          };
        } catch (e) {
          throw e;
        }
      })();
    },
    [txTarget],
  );

  const findTx = async (activeTx: ActiveTx) => {
    try {
      const data = await fetcher<UserTxsResponse, UserTxsRequest>(
        `${BS_API_GATEWAY_URL}/solowallet/transactions`,
        "POST",
        {
          userId: BITSACCO_INTERNAL_USER_ID,
          pagination: {
            page: 0,
            size: 1,
          },
        },
      );
      const tx = validate({ txId: activeTx.id, ledger: data.ledger });

      if (tx.status === TransactionStatus.PROCESSING) {
        updateSharesTx({
          status: SharesTxStatus.PROCESSING,
        });
      }

      if (tx.status === TransactionStatus.FAILED) {
        updateSharesTx({
          status: SharesTxStatus.FAILED,
        });
      }

      if (tx.status === TransactionStatus.COMPLETE) {
        updateSharesTx({
          status: SharesTxStatus.COMPLETE,
        });
      }

      return {
        ...activeTx,
        state: StatusToTransactionState(tx.status),
      };
    } catch (e) {
      throw e;
    }
  };

  return (
    <TransactionModal
      isOpen={isOpen}
      onClose={onClose}
      createTx={createTx}
      findTx={findTx}
      txTarget={txTarget}
      txType={TransactionType.DEPOSIT}
      fixedAmount={sharesTxValue}
      additionalInfo="Each Bitsacco share is valued at 1,000 KES"
      pollTimeoutMs={POLL_TIMEOUT_MS * 3}
      manualVerify={true}
    />
  );
});
