import React, { useState, useCallback, useEffect } from "react";
import {
  Button,
  Flex,
  Text,
  useToast,
  Icon,
  Card,
  CardBody,
  Heading,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Badge,
  Center,
  Divider,
  UnorderedList,
  useDisclosure,
  ButtonGroup,
  CardFooter,
  Box,
} from "@chakra-ui/react";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  btcToFiat,
  Chama,
  ChamaMember,
  ChamaMemberRole,
  ChamaTx,
  ChamaTxReview,
  ChamaTxState,
  ChamaTxType,
  FindChama,
  ReviewChamaTx,
  UpdateChama,
  UpdateChamaTx,
  User,
} from "@bitsacco/types";
import {
  ActivityMenu,
  AlertId,
  AlertStatus,
  AppAlert,
  ChamaSavingsDepositModal,
  ComingSoon,
  FetchHeadshot,
  Headshot,
  InviteModal,
  RequestWithdrawModal,
  UpdateChamaAdmins,
  useAppAlerts,
  useAppState,
  useAuth,
  useQuote,
} from "../components";
import { BS_API_URL, TOAST_TIMEOUT_MS } from "../configs";
import {
  adminCount,
  createCopyTextFn,
  fetcher,
  formatDate,
  formatNumber,
  getAdminRecommendation,
  isChamaAdmin,
  toggleBalance,
} from "../utils";
import { FaArrowLeft } from "react-icons/fa6";
import { QueryKeys } from "../enums/QueryKeys";

import { ReactComponent as CommunityIcon } from "../assets/svgs/community.svg";
import { Routes } from "../routes";
import { getGroupBalanceMsats, getUserBalanceMsats } from "../services";
import { MemberInfo } from "../components/chama/ChamaInfo";

export interface ChamaPageProps {
  chama: Chama;
  user: User;
  isAdmin: boolean;
}

export const ChamaPage = React.memo(function ChamaPage() {
  const { user } = useAuth();
  const quote = useQuote();
  const { userSettings, updateUserSettings } = useAppState();
  const { appAlerts, registerAppAlert } = useAppAlerts();
  const [searchParams] = useSearchParams();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const toast = useToast();

  const [withdrawTx, setWithdrawTx] = useState<ChamaTx | undefined>(undefined);

  const {
    isOpen: showInviteModal,
    onOpen: onOpenInviteModal,
    onClose: onCloseInviteModal,
  } = useDisclosure();
  const {
    isOpen: showDepositModal,
    onOpen: onOpenDepositModal,
    onClose: onCloseDepositModal,
  } = useDisclosure();

  const {
    isOpen: showWithdrawModal,
    onOpen: onOpenWithdrawModal,
    onClose: onCloseWithdrawModal,
  } = useDisclosure();

  const chamaId = searchParams.get("id") || "";

  if (!chamaId) {
    return (
      <Flex
        flexDir={"column"}
        alignItems={"center"}
        justifyContent={"center"}
        border={"2px solid teal"}
        gap={3}
        p={10}
      >
        <Text fontSize={"20px"} fontWeight={"bold"} textAlign={"center"}>
          Chama Details
        </Text>
        <Center h={"220px"} w={"220px"}>
          <Center h={"210px"} w={"210px"} overflow={"hidden"}>
            <Icon as={CommunityIcon} height={"100%"} width={"100%"} />
          </Center>
        </Center>
        <Text textAlign={"center"} pb={6}>
          please a chama from the gallery to see it's details
        </Text>
        <Button
          height={"45px"}
          minW={"150px"}
          colorScheme="teal"
          onClick={() => navigate(Routes.Chama)}
        >
          See All Chamas
        </Button>
      </Flex>
    );
  }

  const {
    data: chama = undefined,
    isLoading: chamaLoading,
    error: chamaError,
  } = useQuery<Chama | undefined, Error>({
    queryKey: [QueryKeys.FIND_CHAMA],
    queryFn: async () => {
      const fetchedChamas = await fetcher<Chama[], FindChama>(
        `${BS_API_URL}/chama/find`,
        "POST",
        {
          id: chamaId,
        },
      );

      return fetchedChamas && fetchedChamas[0];
    },
  });

  const {
    data: txs = [],
    isLoading: txLoading,
    error: txError,
  } = useQuery<ChamaTx[], Error>({
    queryKey: [QueryKeys.CHAMA_TX, chamaId],
    queryFn: async () => {
      const txs = await fetcher<ChamaTx[], Error>(
        `${BS_API_URL}/chama/tx/all/${chamaId}`,
      );
      return txs.reverse();
    },
  });

  useEffect(() => {
    if (!chama) {
      return;
    }

    const admins = adminCount(chama.members);
    const recommendedAdmins = getAdminRecommendation(chama.members, admins);

    if (recommendedAdmins > 0) {
      registerAppAlert({
        id: AlertId.ChamaAdmin,
        status: AlertStatus.Warning,
        description: (
          <UpdateChamaAdmins
            admins={admins}
            recommendedAdmins={recommendedAdmins}
          />
        ),
      });
    }
  }, [chama, registerAppAlert]);

  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 10;

  const totalPages = Math.ceil(txs.length / itemsPerPage);
  const paginatedTxs = txs.slice(
    currentPage * itemsPerPage,
    (currentPage + 1) * itemsPerPage,
  );

  const reviewTxMutation = useMutation<ChamaTx, Error, ReviewChamaTx>({
    mutationFn: (payload: ReviewChamaTx) =>
      fetcher(`${BS_API_URL}/chama/tx/review`, "POST", payload),
    onSuccess: () => {
      queryClient.refetchQueries({
        queryKey: [QueryKeys.CHAMA_TX],
      });
      toast({
        title: "Success",
        description: "Successfully reviewed transaction",
        status: "success",
        duration: TOAST_TIMEOUT_MS,
        isClosable: true,
      });
    },
    onError: (e) => {
      console.error("error reviewing transaction:", e);
      toast({
        title: "Error",
        description: "Error reviewing transaction",
        status: "error",
        duration: TOAST_TIMEOUT_MS,
        isClosable: true,
      });
    },
  });

  const cancelTxMutation = useMutation<ChamaTx, Error, UpdateChamaTx>({
    mutationFn: (payload: UpdateChamaTx) =>
      fetcher(`${BS_API_URL}/chama/tx/update`, "PATCH", payload),
    onSuccess: () => {
      queryClient.refetchQueries({
        queryKey: [QueryKeys.CHAMA_TX],
      });
      toast({
        title: "Success",
        description: "Successfully cancelled transaction",
        status: "success",
        duration: TOAST_TIMEOUT_MS,
        isClosable: true,
      });
    },
    onError: (e) => {
      console.error("error cancelling transaction:", e);
      toast({
        title: "Error",
        description: "Error cancelling transaction",
        status: "error",
        duration: TOAST_TIMEOUT_MS,
        isClosable: true,
      });
    },
  });

  const reviewTx = useCallback(
    (tx: ChamaTx, review: ChamaTxReview) => {
      if (!chama || !user) return;

      const reviewer = chama.members[user.id];
      if (!reviewer) {
        console.error("error reviewing transaction: member not found");
        toast({
          title: "Error",
          description: "Error reviewing transaction",
          status: "error",
          duration: TOAST_TIMEOUT_MS,
          isClosable: true,
        });
        return;
      }

      reviewTxMutation.mutate({
        id: tx.id,
        reviewer: {
          id: reviewer.id,
          role: reviewer.role,
        },
        review,
      });
    },
    [chama, user, toast, reviewTxMutation],
  );

  const cancelTx = useCallback(
    (tx: ChamaTx) => {
      cancelTxMutation.mutate({
        id: tx.id,
        updates: {
          state: ChamaTxState.Failed,
        },
      });
    },
    [cancelTxMutation],
  );

  const showBalanceInSats = useCallback(
    (amountMsats: number, hideBalances: boolean = false) => {
      return (
        <Heading size="md" fontWeight="bold">
          {hideBalances ? (
            <span>{"\u2022".repeat(6)}</span>
          ) : (
            <>&#8383; {formatNumber(amountMsats / 1000)} SATS</>
          )}
        </Heading>
      );
    },
    [],
  );

  const showBalanceInFiat = useCallback(
    (amountMsats: number, hideBalances: boolean = false) => {
      if (!quote) {
        return <></>;
      }
      const { amountFiat } = btcToFiat({
        amountMsats,
        fiatToBtcRate: Number(quote!.rate),
      });

      return (
        <Text pt="2">
          {hideBalances ? (
            <span>{"\u2022".repeat(4)}</span>
          ) : (
            <>
              &#61; <strong>{formatNumber(amountFiat)}</strong> KES
            </>
          )}
        </Text>
      );
    },
    [quote],
  );

  const renderUserBalance = useCallback(() => {
    if (!chama || !user) {
      return <></>;
    }

    const amountMsats = getUserBalanceMsats(chama.members, user.id);

    return (
      <Flex
        direction="column"
        flexGrow={1}
        w={{ base: "100%", md: "auto" }}
        gap="3"
      >
        <Flex direction={"row"} alignItems={"center"} gap="3">
          <Heading size="xs" pb="2">
            My Funds
          </Heading>
          <Button
            background={"none"}
            _hover={{ background: "none" }}
            onClick={() => toggleBalance(userSettings, updateUserSettings)}
            p={0}
            minW={0}
            h={"auto"}
            ml={1}
            mb={2}
          >
            {userSettings.hideBalances ? <ViewIcon /> : <ViewOffIcon />}
          </Button>
        </Flex>
        {showBalanceInSats(amountMsats, userSettings.hideBalances)}
        {showBalanceInFiat(amountMsats, userSettings.hideBalances)}
      </Flex>
    );
  }, [user, chama, userSettings]);

  const renderGroupBalance = useCallback(() => {
    if (!chama) {
      return <></>;
    }

    const amountMsats = getGroupBalanceMsats(chama.members);

    return (
      <Flex
        direction="column"
        flexGrow={1}
        w={{ base: "100%", md: "auto" }}
        gap="3"
      >
        <Heading size="xs" pb="2">
          Group Funds
        </Heading>
        {showBalanceInSats(amountMsats)}
        {showBalanceInFiat(amountMsats)}
      </Flex>
    );
  }, [chama]);

  if (!user || !chama) {
    return <></>;
  }

  const isAdmin = isChamaAdmin(chama.members, user.id);

  const updateChama = (payload: UpdateChama, context: string) => {
    try {
      (async () => {
        const updated = await fetcher<Chama, UpdateChama>(
          `${BS_API_URL}/chama/update`,
          "PATCH",
          payload,
        );

        if (updated) {
          toast({
            title: "Success",
            description: `Successfully ${context}`,
            status: "success",
            duration: TOAST_TIMEOUT_MS,
            isClosable: true,
          });
        } else {
          toast({
            title: "Error",
            description: `Failed to ${context}`,
            status: "error",
            duration: TOAST_TIMEOUT_MS,
            isClosable: true,
          });
        }
      })();
    } catch (e) {
      console.error(e);
      toast({
        title: "Error",
        description: "Error when updating chama",
        status: "error",
        duration: TOAST_TIMEOUT_MS,
        isClosable: true,
      });
    }
  };

  const configureAdmin = (member: ChamaMember) => {
    updateChama(
      { id: chama.id, updates: { members: { [member.id]: member } } },
      "reconfigured chama admins",
    );
  };

  const memberSort = Object.entries(chama.members).sort(([, a], [, b]) =>
    a.role === ChamaMemberRole.Admin
      ? -1
      : b.role === ChamaMemberRole.Admin
        ? 1
        : 0,
  );

  return (
    <>
      <Flex direction="column" flexGrow={1} basis="100%" gap="10" p="5" pb="20">
        <Flex
          p="5"
          gap="4"
          direction="row"
          flexWrap={{ base: "wrap", lg: "nowrap" }}
          align="center"
          justifyItems="center"
          justify="space-between"
        >
          <Flex
            direction="row"
            flexWrap={{ base: "wrap", lg: "nowrap" }}
            justify={{ base: "start", lg: "center" }}
            align={{ base: "start", lg: "center" }}
            gap={2}
          >
            <Heading size="md">{chama?.name}</Heading>
            <Text>{chama?.description}</Text>
          </Flex>
          <Button
            variant="outline"
            colorScheme="teal"
            height="35px"
            onClick={() => navigate(Routes.Chama)}
          >
            <Icon as={FaArrowLeft} mr="2" />
            back
          </Button>
        </Flex>

        <Box w={"100%"}>
          {appAlerts.map((alert, idx) => (
            <AppAlert {...alert} key={idx} />
          ))}
        </Box>

        <Flex
          display={"flex"}
          alignItems={"center"}
          justifyItems={"center"}
          justifyContent={"center"}
          w={"100%"}
        >
          <Card w={{ base: "800px", xl: "1000px" }}>
            <CardBody
              display={"flex"}
              flexDirection={"column"}
              alignItems={"center"}
              justifyItems={"center"}
              justifyContent={"center"}
              gap={3}
            >
              <Flex direction="column" w="100%" flexGrow={1} gap={4}>
                <Flex
                  flexDirection="column"
                  px={{ base: "", md: "3" }}
                  gap={{ base: "6", lg: "6" }}
                >
                  <Heading size="sm" textTransform="uppercase">
                    Funds
                  </Heading>
                  <Flex
                    display="flex"
                    flexDirection="row"
                    flexWrap={{ base: "wrap", lg: "nowrap" }}
                    justifyContent="space-between"
                    px={{ base: "", md: "3" }}
                    gap={{ base: "6", lg: "6" }}
                  >
                    {renderGroupBalance()}
                    <Center height={{ base: "0%", sm: "80px" }}>
                      <Divider orientation="vertical" />
                    </Center>
                    {renderUserBalance()}
                  </Flex>
                </Flex>

                <Divider orientation="horizontal" />

                <Flex direction="column" gap={4} flexGrow={1}>
                  <Flex
                    gap={"4"}
                    direction="row"
                    flexWrap={{ base: "wrap", lg: "nowrap" }}
                    align="center"
                    alignItems={"center"}
                    justifyItems={"center"}
                    justify="space-between"
                  >
                    <Heading size="sm" textTransform="uppercase">
                      Members
                    </Heading>
                    {isAdmin && (
                      <Button
                        variant="outline"
                        colorScheme="green"
                        height={"35px"}
                        onClick={onOpenInviteModal}
                      >
                        + invite
                      </Button>
                    )}
                  </Flex>
                  <InviteModal
                    chama={chama}
                    isOpen={showInviteModal}
                    onClose={onCloseInviteModal}
                  />

                  <Flex
                    pt={2}
                    flexDirection="row"
                    flexWrap={{ base: "wrap" }}
                    gap={2}
                  >
                    {memberSort.map(([idx, member]) => {
                      return (
                        <MemberInfo
                          key={idx}
                          member={member}
                          user={user}
                          showMenu={isAdmin}
                          adminCount={adminCount(chama.members)}
                          configureAdmin={configureAdmin}
                          removeMember={console.log}
                        />
                      );
                    })}
                  </Flex>
                  <Divider orientation="horizontal" />
                  <Heading size="sm" textTransform="uppercase">
                    Rules
                  </Heading>
                  <UnorderedList gap={2}>
                    <ComingSoon />
                  </UnorderedList>
                </Flex>
              </Flex>
            </CardBody>

            <Center>
              <Divider orientation="horizontal" border={"1px solid teal"} />
            </Center>

            <CardFooter>
              <ButtonGroup
                w="100%"
                display="flex"
                flexDirection="row"
                flexWrap={{ base: "wrap", lg: "nowrap" }}
                justifyContent={{ base: "center", lg: "start" }}
                gap={"4"}
                spacing={{ base: "0", lg: "4" }}
              >
                <Button
                  height={"45px"}
                  minW={"150px"}
                  flexGrow={1}
                  variant="solid"
                  colorScheme="green"
                  onClick={onOpenDepositModal}
                >
                  Deposit Funds
                </Button>
                <Button
                  height={"45px"}
                  minW={"150px"}
                  flexGrow={1}
                  variant="solid"
                  colorScheme="teal"
                  onClick={onOpenWithdrawModal}
                >
                  Request Withdrawal
                </Button>
              </ButtonGroup>
              <ChamaSavingsDepositModal
                depositTarget={{
                  target: chama,
                  user,
                }}
                isOpen={showDepositModal}
                onClose={onCloseDepositModal}
              />
              <RequestWithdrawModal
                chama={chama}
                user={user}
                isAdmin={isAdmin}
                isOpen={showWithdrawModal}
                onClose={() => {
                  onCloseWithdrawModal();
                }}
              />
            </CardFooter>
          </Card>
        </Flex>

        <Flex direction="column">
          <Text mb="4">TRANSACTION HISTORY</Text>
          <Flex direction={"column"} gap="3" width={"100%"}>
            <Flex overflowX={"auto"}>
              <Table variant={"striped"} minWidth={"650px"}>
                <Thead>
                  <Tr>
                    <Th>Date</Th>
                    <Th>Description</Th>
                    <Th isNumeric>Amount (Sats)</Th>
                    <Th>Member</Th>
                    <Th>Type</Th>
                    <Th>Status</Th>
                    <Th>Actions</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {paginatedTxs.map((tx) => (
                    <Tr key={tx.id}>
                      <Td>{formatDate(tx.meta.timestamp.toString())}</Td>
                      <Td>{tx.meta.description}</Td>
                      <Td isNumeric>{(tx.amount / 1000).toFixed(2)}</Td>
                      <Td>
                        {tx.meta.user === user.id ? (
                          <Headshot user={user} />
                        ) : (
                          <FetchHeadshot id={tx.meta.user} />
                        )}
                      </Td>
                      <Td>
                        <Badge
                          colorScheme={getTxTypeColor(tx.type)}
                          variant="outline"
                          borderRadius={5}
                        >
                          {tx.type}
                        </Badge>
                      </Td>
                      <Td>
                        <Badge
                          colorScheme={getTxStateColor(tx.state)}
                          borderRadius={5}
                        >
                          {tx.state}
                        </Badge>
                      </Td>
                      <Td>
                        {chama && (
                          <ActivityMenu
                            tx={tx}
                            member={chama.members[user.id]!}
                            isAdmin={isAdmin}
                            copyTxId={() =>
                              createCopyTextFn(toast)("transaction id", tx.id)
                            }
                            reviewTx={(rv: ChamaTxReview) => reviewTx(tx, rv)}
                            cancelTx={() => cancelTx(tx)}
                            withdraw={
                              tx.state === ChamaTxState.Approved
                                ? () => setWithdrawTx(tx)
                                : undefined
                            }
                          />
                        )}
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </Flex>
            <Flex justify="space-between" mt="4">
              <Button
                onClick={() => setCurrentPage(currentPage - 1)}
                isDisabled={currentPage === 0}
              >
                previous
              </Button>
              <Text>
                {totalPages > 0
                  ? `page ${currentPage + 1} of ${totalPages}`
                  : ""}
              </Text>
              <Button
                onClick={() => setCurrentPage(currentPage + 1)}
                isDisabled={currentPage >= totalPages - 1}
              >
                next
              </Button>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      {/* {activeSharesTx && user && (
        <SharesTxModal
          sharesTxValue={activeSharesTx.quantity * BITSACCO_SHARE_VALUE_KES}
          txTarget={{
            target: {
              id: activeSharesTx.id,
              name: `pay for ${activeSharesTx.quantity} bitsacco shares`,
            },
            user,
          }}
          isOpen={showSharesTxModal}
          onClose={() => {
            onCloseSharesTxModal();
            setActiveSharesTx(undefined);
          }}
          updateSharesTx={updateSharesTx}
        />
      )} */}
    </>
  );
});

const getTxTypeColor = (type: ChamaTxType) => {
  switch (type) {
    case ChamaTxType.Deposit:
      return "green";
    case ChamaTxType.Withdrawal:
      return "red";
  }
};

const getTxStateColor = (state: ChamaTxState) => {
  switch (state) {
    case ChamaTxState.Pending:
    case ChamaTxState.Approved:
      return "teal";
    case ChamaTxState.Rejected:
    case ChamaTxState.Failed:
      return "red";
    case ChamaTxState.Complete:
      return "green";
  }
};
