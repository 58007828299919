import React, { useEffect } from "react";
import { Box } from "@chakra-ui/react";
import { BS_ENV } from "../configs";
import {
  useAppAlerts,
  AlertId,
  AlertStatus,
  InsufficientSharesInfo,
  BitsaccoDemoInfo,
  BitsaccoPilotInfo,
  ServiceMaintenanceWarning,
  AppAlert,
} from "./AppAlert";
import { useAppState } from "./Providers";
import { Routes } from "../routes";

export const AlertBox = React.memo(function AlertBox() {
  const { appState } = useAppState();

  const { appAlerts, hasAppAlert, registerAppAlert, removeAppAlerts } =
    useAppAlerts();

  useEffect(() => {
    if (!location.pathname) {
      return;
    }

    switch (location.pathname) {
      case Routes.Personal:
      case Routes.Chama:
      case Routes.Membership:
        if (!appState.memberStatus.hasShares) {
          registerAppAlert({
            id: AlertId.BuyShares,
            status: AlertStatus.Info,
            description: <InsufficientSharesInfo />,
          });
        } else {
          if (BS_ENV === "development") {
            return registerAppAlert({
              id: AlertId.Demo,
              status: AlertStatus.Warning,
              description: <BitsaccoDemoInfo />,
            });
          } else {
            registerAppAlert({
              id: AlertId.Pilot,
              status: AlertStatus.Info,
              description: <BitsaccoPilotInfo />,
            });
          }

          registerAppAlert({
            id: AlertId.Generic,
            status: AlertStatus.Warning,
            description: <ServiceMaintenanceWarning />,
          });
        }
        return;

      case Routes.Root:
      case Routes.Auth:
        removeAppAlerts([
          AlertId.BuyShares,
          AlertId.Demo,
          AlertId.Pilot,
          AlertId.Generic,
        ]);

        return;
    }
  }, [location, hasAppAlert, registerAppAlert, removeAppAlerts]);

  return (
    <Box
      w={"100%"}
      maxW="1440px"
      px={{ base: "12px", md: "20px", lg: "40px", xl: "100px" }}
    >
      {appAlerts.map((alert, idx) => (
        <AppAlert {...alert} key={idx} />
      ))}
    </Box>
  );
});
