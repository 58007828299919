export enum Routes {
  Root = "/",
  Auth = "/auth",
  Account = "/account",
  Personal = "/personal",
  Membership = "/membership",
  Chama = "/chama",
  ChamaDetails = "/chama/details",
  Join = "/chama/join",
  Transaction = "/chama/tx",
  Admin = "/admin",
  MemberAdmin = "/admin/membr",
  ShareAdmin = "/admin/shr",
  ChamaAdmin = "/admin/chamr",
}
