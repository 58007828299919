import React, { useCallback } from "react";
import {
  OfframpSwapTarget,
  PaginatedSolowalletTxsResponse,
  SolowalletTx,
  TransactionType,
  TxPath,
  UserTxsRequest,
  UserTxsResponse,
  WalletMeta,
  WithdrawFundsRequest,
} from "@bitsacco/types";
import { BS_API_GATEWAY_URL } from "../../configs";
import { ActiveTx, TransactionTarget } from "../transactions";
import { StatusToTransactionState } from "../TransactionState";
import { TransactionModal } from "./TransactionModal";
import { fetcher } from "../../utils";

interface PersonalSavingsWithdrawModalProps {
  txTarget: TransactionTarget;
  setUserTransactions: (
    ledger?: PaginatedSolowalletTxsResponse,
    meta?: WalletMeta,
  ) => void;
  isOpen: boolean;
  onClose: () => void;
}

export const PersonalSavingsWithdrawModal = React.memo(
  function PersonalSavingsWithdrawModal({
    txTarget,
    setUserTransactions,
    isOpen,
    onClose,
  }: PersonalSavingsWithdrawModalProps): JSX.Element {
    const validate = ({
      txId,
      ledger,
    }: Partial<UserTxsResponse>): SolowalletTx => {
      if (!ledger?.transactions || ledger.transactions.length < 1) {
        throw new Error("Failed to create a withdraw transaction");
      }

      const tx = ledger.transactions.find((tx) => tx.id === txId);

      if (!tx) {
        throw new Error("Failed to create a withdraw transaction");
      }

      return tx;
    };

    const createTx = useCallback(
      (path: TxPath, amount: number) => {
        return (async (): Promise<ActiveTx> => {
          try {
            const offramp: OfframpSwapTarget | undefined =
              path === TxPath.Mpesa
                ? {
                    currency: "KES" as unknown as any,
                    payout: {
                      phone: txTarget.user.phone?.replace("+", "") || "",
                    },
                  }
                : undefined;

            const res = await fetcher<UserTxsResponse, WithdrawFundsRequest>(
              `${BS_API_GATEWAY_URL}/solowallet/withdraw`,
              "POST",
              {
                userId: txTarget.user.id,
                amountFiat: amount,
                reference: "bitsacco solowallet",
                offramp,
              },
            );

            const tx = validate(res);
            setUserTransactions(res.ledger, res.meta);

            return {
              path,
              id: tx.id,
              type: tx.type,
              lightning: tx.lightning,
              state: StatusToTransactionState(tx.status),
            };
          } catch (e) {
            throw e;
          }
        })();
      },
      [txTarget, setUserTransactions],
    );

    const findTx = async (activeTx: ActiveTx) => {
      try {
        const data = await fetcher<UserTxsResponse, UserTxsRequest>(
          `${BS_API_GATEWAY_URL}/solowallet/transactions`,
          "POST",
          {
            userId: txTarget.user.id,
            pagination: {
              page: 0,
              size: 1,
            },
          },
        );
        const tx = validate({ txId: activeTx.id, ledger: data.ledger });
        return {
          ...activeTx,
          state: StatusToTransactionState(tx.status),
        };
      } catch (e) {
        throw e;
      }
    };

    return (
      <TransactionModal
        isOpen={isOpen}
        onClose={onClose}
        createTx={createTx}
        findTx={findTx}
        txTarget={txTarget}
        txType={TransactionType.WITHDRAW}
      />
    );
  },
);
