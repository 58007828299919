import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormHelperText,
  Text,
  useToast,
} from "@chakra-ui/react";
import { TransactionType, TxPath } from "@bitsacco/types";
import QRCode from "qrcode.react";

import { TOAST_TIMEOUT_MS } from "../../configs";
import { AmountInputGroup } from "../InputGroups";
import { TransactionState, TransactionStateTracker } from "../TransactionState";
import { TransactionUxProps } from "./types";

export const LightningTransaction = React.memo(function LightningTransaction({
  amount,
  activeTx,
  updateAmount,
  createTxButton,
  stateHelperText,
}: TransactionUxProps & { lightning?: { invoice: string } }) {
  const [invoice, setInvoice] = useState<string | undefined>();
  const [depositState, setDepositState] = useState<TransactionState>(
    activeTx?.state || TransactionState.Create,
  );
  const toast = useToast();

  useEffect(() => {
    if (!activeTx) {
      setInvoice(undefined);
      setDepositState(TransactionState.Create);
    } else {
      setDepositState(activeTx.state);
      activeTx.type === TransactionType.DEPOSIT &&
        setInvoice(activeTx.lightning?.invoice);
    }
  }, [activeTx, setInvoice, setDepositState]);

  const copyInvoice = useCallback(
    (invoice: string): void => {
      if (navigator?.clipboard?.writeText) {
        navigator.clipboard.writeText(invoice).then(
          () => {
            console.log("Invoice copied");
            toast({
              title: "Success",
              description: "Copied invoice to clipboard",
              status: "success",
              duration: TOAST_TIMEOUT_MS,
              isClosable: true,
            });
          },
          (err) => {
            console.error("Failed to copy invoice", err);
            toast({
              title: "Error",
              description: "Failed to copy invoice",
              status: "error",
              duration: TOAST_TIMEOUT_MS,
              isClosable: true,
            });
          },
        );
      } else {
        console.error("Clipboard API not supported");
      }
    },
    [toast],
  );

  if (activeTx?.path === TxPath.Mpesa) {
    return (
      <Text>
        Mpesa transaction in progress... please switch to the Mpesa tab
      </Text>
    );
  }

  if (
    depositState === TransactionState.Create ||
    depositState === TransactionState.Pending
  ) {
    return (
      <>
        <FormControl>
          <Box>
            <AmountInputGroup
              amount={amount}
              setAmount={
                updateAmount ? updateAmount : (a: number) => console.log
              }
              isDisabled={!updateAmount}
              getFormHelperText={(amountError?: string) => {
                return amountError ? (
                  <FormHelperText color="red.300">{amountError}</FormHelperText>
                ) : (
                  <></>
                );
              }}
            />
          </Box>
          {invoice && (
            <Flex
              flexDirection="row"
              gap="5"
              h="100%"
              justify="center"
              align="center"
              pt="6"
            >
              <QRCode
                bgColor="#FFFFFF"
                fgColor="#000000"
                value={invoice}
                size={200}
              />
              <FormHelperText>
                This is a Bitcoin Lightning invoice <br />
                Scan it with a lightning wallet <br />
                to pay
              </FormHelperText>
            </Flex>
          )}
          {!invoice && (
            <FormHelperText pt="2">
              you will pay this amount over the bitcoin lightning network
            </FormHelperText>
          )}
        </FormControl>

        {!invoice && (
          <Button
            onClick={() => createTxButton.onClick(TxPath.Lightning)}
            variant="solid"
            colorScheme="green"
          >
            {createTxButton.label}
          </Button>
        )}

        {invoice && (
          <Button
            onClick={() => copyInvoice(invoice)}
            variant="solid"
            colorScheme="green"
          >
            Copy Invoice
          </Button>
        )}
      </>
    );
  }

  return (
    <TransactionStateTracker
      transactionState={depositState}
      stateHelperText={stateHelperText}
      progress={{
        isIndeterminate: true,
        thickness: "4px",
        color: "green.300",
        size: "3em",
      }}
      icon={{ boxSize: "4em" }}
    />
  );
});
