import React, { useCallback } from "react";
import { Box, Flex, useTheme } from "@chakra-ui/react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import {
  Header,
  ApiProvider,
  Footer,
  AuthProvider,
  QuoteProvider,
  AppStateProvider,
  AlertBox,
} from "../components";

import "./Home.css";

export const Home = React.memo(function Home(): JSX.Element {
  const location = useLocation();
  const theme = useTheme();

  const navigate = useNavigate();

  const auth = useCallback(() => {
    navigate("/auth", { state: { from: location.pathname } });
  }, [location, navigate]);

  return (
    <ApiProvider auth={auth}>
      <AuthProvider>
        <QuoteProvider>
          <AppStateProvider>
            <Flex
              direction="column"
              align="center"
              minH="100vh"
              overflowX="hidden"
              className="Home"
            >
              <Header />
              <AlertBox />
              <Flex
                flexGrow={1}
                direction="column"
                w={"100%"}
                maxW="1440px"
                px={{ base: "12px", md: "20px", lg: "40px", xl: "100px" }}
                id="page-experience"
              >
                <Outlet />
              </Flex>
              <Footer />
            </Flex>
          </AppStateProvider>
        </QuoteProvider>
      </AuthProvider>
    </ApiProvider>
  );
});
