import React from "react";
import { TimeIcon, CheckIcon, CloseIcon } from "@chakra-ui/icons";
import {
  CircularProgressProps,
  IconProps,
  Flex,
  CircularProgress,
  Icon,
  Text,
  Badge,
} from "@chakra-ui/react";
import {
  ChamaTxState,
  ChamaTxType,
  MpesaTransactionState,
  TransactionStatus,
  TransactionType,
} from "@bitsacco/types";

export enum TransactionState {
  Create,
  Pending,
  Processing,
  Complete,
  Failed,
  ManualVerify,
}
export interface TransactionStateTrackerProps {
  transactionState: TransactionState;
  progress: CircularProgressProps;
  icon: IconProps;
  stateHelperText: StateHelperText;
}

export interface StateHelperText {
  value: string;
  isError?: boolean;
}

export const TransactionStateTracker = React.memo(
  function TransactionStateTracker({
    transactionState,
    progress,
    icon,
    stateHelperText,
  }: TransactionStateTrackerProps) {
    return (
      <Flex direction="column">
        <Flex justify="center" align="center" h="14em">
          {transactionState === TransactionState.Pending && (
            <CircularProgress {...progress} />
          )}
          {transactionState === TransactionState.ManualVerify && (
            <Icon as={TimeIcon} {...icon} color="teal.500" />
          )}
          {transactionState === TransactionState.Processing && (
            <Icon as={TimeIcon} {...icon} color="green.500" />
          )}
          {transactionState === TransactionState.Complete && (
            <Icon as={CheckIcon} {...icon} color="green.500" />
          )}
          {transactionState === TransactionState.Failed && (
            <Icon as={CloseIcon} {...icon} color="red.300" />
          )}
        </Flex>
        <Text color={stateHelperText.isError ? "red.300" : "green500"}>
          {stateHelperText.value}
        </Text>
      </Flex>
    );
  },
);

export function MpesaToTransactionState(
  mpesa: MpesaTransactionState,
): TransactionState {
  switch (mpesa) {
    case MpesaTransactionState.Pending:
      return TransactionState.Pending;
    case MpesaTransactionState.Processing:
      return TransactionState.Processing;
    case MpesaTransactionState.Complete:
      return TransactionState.Complete;
    case MpesaTransactionState.Failed:
      return TransactionState.Failed;
    default:
      return TransactionState.Create;
  }
}

export function ChamaToTransactionState(state: ChamaTxState): TransactionState {
  switch (state) {
    case ChamaTxState.Pending:
      return TransactionState.Pending;
    case ChamaTxState.Approved:
      return TransactionState.Processing;
    case ChamaTxState.Complete:
      return TransactionState.Complete;
    case ChamaTxState.Rejected:
    case ChamaTxState.Failed:
      return TransactionState.Failed;
    default:
      return TransactionState.Create;
  }
}

export function ChamaToTransactionType(type: ChamaTxType): TransactionType {
  switch (type) {
    case ChamaTxType.Deposit:
      return TransactionType.DEPOSIT;
    case ChamaTxType.Withdrawal:
      return TransactionType.WITHDRAW;
  }
}

export function StatusToTransactionState(
  status: TransactionStatus,
): TransactionState {
  switch (status) {
    case TransactionStatus.PENDING:
      return TransactionState.Pending;
    case TransactionStatus.PROCESSING:
      return TransactionState.Processing;
    case TransactionStatus.COMPLETE:
      return TransactionState.Complete;
    case TransactionStatus.FAILED:
      return TransactionState.Failed;
    default:
      return TransactionState.Create;
  }
}

export const getTxStatusBadge = (status: TransactionStatus) => {
  switch (status) {
    case TransactionStatus.PENDING:
      return (
        <Badge colorScheme="teal" borderRadius={5}>
          pending
        </Badge>
      );
    case TransactionStatus.PROCESSING:
      return (
        <Badge colorScheme="teal" borderRadius={5}>
          processing
        </Badge>
      );
    case TransactionStatus.UNRECOGNIZED:
    case TransactionStatus.FAILED:
      return (
        <Badge colorScheme="red" borderRadius={5}>
          error
        </Badge>
      );
    case TransactionStatus.COMPLETE:
      return (
        <Badge colorScheme="green" borderRadius={5}>
          success
        </Badge>
      );
  }
};

export const getTxTypeBadge = (type: TransactionType) => {
  switch (type) {
    case TransactionType.DEPOSIT:
      return (
        <Badge colorScheme="green" borderRadius={5}>
          deposit
        </Badge>
      );
    case TransactionType.WITHDRAW:
      return (
        <Badge colorScheme="teal" borderRadius={5}>
          withdraw
        </Badge>
      );
    case TransactionType.UNRECOGNIZED:
      return (
        <Badge colorScheme="red" borderRadius={5}>
          unknown
        </Badge>
      );
  }
};
