import React, { useCallback, useState } from "react";
import {
  Button,
  ButtonGroup,
  Flex,
  FormControl,
  FormLabel,
  Select,
  Text,
} from "@chakra-ui/react";
import { SharesTx, SharesTxStatus, SharesTxUpdates } from "@bitsacco/types";

import { TemplateModal } from "./TemplateModal";
import { ShareInputGroup } from "../InputGroups";
import { getProfileLabel } from "../../utils";

interface UpdateShareTxModalProps {
  tx: SharesTx;
  isOpen: boolean;
  onClose: () => void;
  updateShareTx: (updates: SharesTxUpdates) => void;
}

export const UpdateShareTxModal = React.memo(function UpdateShareTxModal({
  tx,
  isOpen,
  onClose,
  updateShareTx,
}: UpdateShareTxModalProps): JSX.Element {
  const [updates, setUpdates] = useState<SharesTxUpdates>({
    quantity: tx.quantity,
    status: tx.status,
    offerId: tx.offerId,
  });

  const closeModal = useCallback(() => {
    setUpdates({});
    onClose();
  }, [onClose, setUpdates]);

  const getModalBody = useCallback(() => {
    const updateQuantity = (q: number) => {
      setUpdates((prev) => ({ ...prev, quantity: q }));
    };

    const updateStatus = (s: SharesTxStatus) => {
      setUpdates((prev) => ({ ...prev, status: s }));
    };

    const updateOfferId = (id: string) => {
      setUpdates((prev) => ({ ...prev, offerId: id }));
    };

    return (
      <Flex flexDirection="column" gap="5">
        <Text fontSize="md" color="teal.500">
          Update Share TX {tx.id}
        </Text>
        <FormControl display="flex" flexDirection="column" gap={3}>
          <FormLabel>Update Share Status</FormLabel>
          <Select
            placeholder="Select Status"
            value={updates.status || ""}
            onChange={(e) => {
              const s = Number(e.target.value) as unknown as SharesTxStatus;
              setUpdates((prev) => ({ ...prev, status: s }));
            }}
          >
            <option value={SharesTxStatus.APPROVED}>APPROVED</option>
            <option value={SharesTxStatus.COMPLETE}>COMPLETE</option>
            <option value={SharesTxStatus.UNRECOGNIZED}>UNRECOGNIZED</option>
          </Select>
          <FormLabel>Update Share Quantity</FormLabel>
          <ShareInputGroup
            shares={updates.quantity || 0}
            setShare={updateQuantity}
            getFormHelperText={() => <></>}
          />
        </FormControl>
      </Flex>
    );
  }, [tx, updates, setUpdates]);

  const getModalActions = useCallback(() => {
    return (
      <>
        <Button onClick={() => updateShareTx(updates)}>
          Update Transaction
        </Button>
        <Button
          size="lg"
          onClick={closeModal}
          variant="ghost"
          colorScheme="red"
        >
          Cancel
        </Button>
      </>
    );
  }, [updates, updateShareTx, closeModal]);

  const getModalFooter = useCallback(() => {
    return (
      <ButtonGroup
        w="100%"
        display="flex"
        flexDirection="row"
        flexWrap={{ base: "wrap", lg: "nowrap" }}
        justifyContent="space-between"
        spacing={{ base: "0", lg: "4" }}
        gap={"2"}
      >
        {getModalActions()}
      </ButtonGroup>
    );
  }, [getModalActions]);

  return (
    <TemplateModal
      isOpen={isOpen}
      onClose={closeModal}
      header={<Text>{`Update Share Transaction ${tx.id}`}</Text>}
      body={getModalBody()}
      footer={getModalFooter()}
    />
  );
});
