import React, { useCallback, useState } from "react";
import {
  ButtonGroup,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Divider,
  Flex,
  Heading,
  Text,
  useDisclosure,
  Center,
  Box,
} from "@chakra-ui/react";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import { btcToFiat, Chama, User } from "@bitsacco/types";

import {
  adminCount,
  formatNumber,
  getAdminRecommendation,
  toggleBalance,
  truncateText,
} from "../../utils";
import { Routes } from "../../routes";
import { getUserBalanceMsats, getGroupBalanceMsats } from "../../services";
import { useAppState, useQuote } from "../Providers";
import { ChamaSavingsDepositModal } from "../modal";
import { RouterLink } from "../RouterLink";
import { useEffect } from "react";

enum ChamaState {
  Success = "success",
  Warning = "warning",
}

interface ChamaCardProps {
  user: User;
  chama: Chama;
}

export const ChamaCard = React.memo(function ChamaCard({
  user,
  chama,
}: ChamaCardProps) {
  const { userSettings, updateUserSettings } = useAppState();
  const [chamaState, setChamaState] = useState<ChamaState>(ChamaState.Success);
  const {
    isOpen: showDepositModal,
    onOpen: onOpenDepositModal,
    onClose: onCloseDepositModal,
  } = useDisclosure();
  const quote = useQuote();

  useEffect(() => {
    if (!chama) {
      return;
    }

    const admins = adminCount(chama.members);
    const recommendedAdmins = getAdminRecommendation(chama.members, admins);

    if (recommendedAdmins > 0) {
      setChamaState(ChamaState.Warning);
    }
  }, [chama, setChamaState]);

  const showBalanceInSats = useCallback(
    (amountMsats: number, hideBalances: boolean = false) => {
      return (
        <Heading size="md" fontWeight="bold">
          {hideBalances ? (
            <span>{"\u2022".repeat(4)}</span>
          ) : (
            <>&#8383; {formatNumber(amountMsats / 1000)} SATS</>
          )}
        </Heading>
      );
    },
    [],
  );

  const showBalanceInFiat = useCallback(
    (amountMsats: number, hideBalances: boolean = false) => {
      if (!quote) {
        return <></>;
      }
      const { amountFiat } = btcToFiat({
        amountMsats,
        fiatToBtcRate: Number(quote!.rate),
      });

      return (
        <Text>
          {hideBalances ? (
            <span>{"\u2022".repeat(4)}</span>
          ) : (
            <>
              &#61; <strong>{formatNumber(amountFiat)}</strong> KES
            </>
          )}
        </Text>
      );
    },
    [quote],
  );

  const renderUserBalance = useCallback(() => {
    const amountMsats = getUserBalanceMsats(chama.members, user.id);

    return (
      <Flex direction="column" flexGrow={1} w={{ base: "100%", md: "auto" }}>
        <Flex
          direction="row"
          flexGrow={1}
          w="100%"
          justifyContent="space-between"
          pb="3"
        >
          <Heading size="s" pb="2">
            My Funds
          </Heading>
          <Button
            background={"none"}
            _hover={{ background: "none" }}
            onClick={() => toggleBalance(userSettings, updateUserSettings)}
            minW={0}
            h={"auto"}
            pb={2}
          >
            {userSettings.hideBalances ? <ViewIcon /> : <ViewOffIcon />}
          </Button>
        </Flex>
        <Flex
          direction="row"
          flexGrow={1}
          w="100%"
          px="5"
          justifyContent="space-between"
          alignItems="center"
        >
          {showBalanceInSats(amountMsats, userSettings.hideBalances)}
          {showBalanceInFiat(amountMsats, userSettings.hideBalances)}
        </Flex>
      </Flex>
    );
  }, [user, chama, userSettings]);

  const renderGroupBalance = useCallback(() => {
    const amountMsats = getGroupBalanceMsats(chama.members);

    return (
      <Flex direction="column" flexGrow={1} w={{ base: "100%", md: "auto" }}>
        <Heading size="s" pb="2">
          Group Funds
        </Heading>
        <Flex
          direction="row"
          flexGrow={1}
          w="100%"
          px="5"
          justifyContent="space-between"
          alignItems="center"
        >
          {showBalanceInSats(amountMsats)}
          {showBalanceInFiat(amountMsats)}
        </Flex>
      </Flex>
    );
  }, [chama]);

  return (
    <>
      <Card minW="m" variant="elevated" colorScheme="teal" boxShadow="dark-lg">
        <CardHeader
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
        >
          <Flex gap={2} alignItems="start" flexDirection="column" flexGrow={1}>
            <Heading size="md">{chama.name}</Heading>
            <Text>{truncateText(chama.description, 25)}</Text>
          </Flex>
          <StatusDot value={chamaState} />
        </CardHeader>

        <Center>
          <Divider orientation="horizontal" border={"1px solid teal"} />
        </Center>

        <Center>
          <Flex direction="row" alignItems="centre" gap={4} flexGrow={1} p="4">
            <Text>{Object.entries(chama.members).length} members</Text>
            <Text>{adminCount(chama.members)} admins</Text>
          </Flex>
        </Center>

        <Center>
          <Divider orientation="horizontal" border={"1px solid teal"} />
        </Center>

        <CardBody>
          <Flex direction="column" gap={4}>
            <Flex flexDirection="column" gap="3">
              <Flex
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                gap="6"
              >
                {renderGroupBalance()}
                <Divider orientation="horizontal" />
                {renderUserBalance()}
              </Flex>
            </Flex>
          </Flex>
        </CardBody>

        <Center>
          <Divider orientation="horizontal" border={"1px solid teal"} />
        </Center>

        <CardFooter>
          <ButtonGroup
            w="100%"
            display="flex"
            flexDirection="row"
            flexWrap={{ base: "wrap", lg: "nowrap" }}
            justifyContent={{ base: "center", md: "start" }}
            gap={"2"}
            spacing={{ base: "0", md: "2" }}
          >
            <Button
              height={"45px"}
              minW={"100px"}
              flexGrow={1}
              variant="solid"
              colorScheme="green"
              onClick={onOpenDepositModal}
            >
              Deposit Funds
            </Button>
            <RouterLink to={`${Routes.ChamaDetails}/?id=${chama.id}`}>
              <Button
                height={"45px"}
                minW={"100px"}
                flexGrow={1}
                variant="outline"
                colorScheme="teal"
                m={0}
              >
                See Details
              </Button>
            </RouterLink>
          </ButtonGroup>
        </CardFooter>
      </Card>
      <ChamaSavingsDepositModal
        depositTarget={{
          target: chama,
          user,
        }}
        isOpen={showDepositModal}
        onClose={onCloseDepositModal}
      />
    </>
  );
});

const StatusDot = ({ value }: { value: ChamaState }) => {
  const colorMap: { [key: string]: string } = {
    success: "green.500",
    warning: "yellow.500",
    error: "red.500",
  };

  return (
    <Box
      w="10px"
      h="10px"
      borderRadius="full"
      bg={colorMap[value] || "gray.500"}
    />
  );
};
