import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { WebLNProvider } from "@webbtc/webln-types";
import {
  AppState,
  User,
  QuoteResponse,
  UserShareTxsResponse,
  UserSettings,
} from "@bitsacco/types";
import {
  setSessionValue,
  SESSION_USER_KEY,
  removeSessionValue,
} from "../services";
import { BitsaccoApi } from "../Api";
import { BS_API_GATEWAY_URL } from "../configs";
import { fetcher } from "../utils";

interface ApiContextValue {
  bitsacco: BitsaccoApi;
  webln: WebLNProvider | null;
}

const ApiContext = createContext<ApiContextValue>({
  bitsacco: new BitsaccoApi(),
  webln: null,
});

export const ApiProvider = React.memo(function ApiProvider({
  auth,
  children,
}: {
  auth: () => void;
  children: React.ReactNode;
}): JSX.Element {
  const bitsacco = useMemo(() => new BitsaccoApi(auth), [auth]);
  const [webln, setWebln] = useState<WebLNProvider | null>(null);

  // WebLN
  useEffect(() => {
    (async () => {
      // const webln = await detectWebLNProvider();
      webln && setWebln(webln);
    })();
  }, [webln, setWebln]);

  return (
    <ApiContext.Provider
      value={{
        bitsacco,
        webln,
      }}
    >
      {children}
    </ApiContext.Provider>
  );
});

interface AuthContextValue {
  user: User | null;
  login: (user: User, redirect?: string) => void;
  logout: () => void;
  nextRedirect: (redirect?: string) => void;
}

const AuthContext = createContext<AuthContextValue>({
  user: null,
  login: (user: User, redirect?: string) =>
    console.log(`login: ${user} and redirecting to ${redirect}`),
  logout: () => console.log("logout"),
  nextRedirect: (redirect?: string) =>
    console.log(`redirecting to ${redirect}`),
});

export const AuthProvider = React.memo(function UserProvider({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element {
  const navigate = useNavigate();
  const { state } = useLocation();

  const [user, setUser] = useState<User | null>(null);
  const [next, setNext] = useState<string | undefined>();

  const login = useCallback(
    (user: User, redirect?: string) => {
      setUser(user);
      setSessionValue(SESSION_USER_KEY, JSON.stringify(user));
      navigate(redirect || next || state?.from || "/", {
        replace: true,
      });
    },
    [next, state, setUser, navigate],
  );

  const logout = useCallback(() => {
    setUser(null);
    setNext(undefined);
    removeSessionValue(SESSION_USER_KEY);
    navigate("/");
  }, [setUser, navigate]);

  return (
    <AuthContext.Provider
      value={{
        user,
        login,
        logout,
        nextRedirect: setNext,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
});

export const QuoteContext = createContext<QuoteResponse | null>(null);

export const QuoteProvider = React.memo(function QuoteProvider({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element {
  const [quote, setQuote] = useState<QuoteResponse | null>(null);

  const fetchQuote = useCallback(async () => {
    try {
      const quote = await fetcher<QuoteResponse, undefined>(
        `${BS_API_GATEWAY_URL}/swap/onramp/quote?currency=KES&amount=0`,
        "GET",
      );
      setQuote(quote);
    } catch (error) {
      console.error("Failed to fetch generic quote:", error);
    }
  }, []);

  useEffect(() => {
    fetchQuote();

    const intervalId = setInterval(
      () => {
        fetchQuote();
        // Fetch quote every 10 minutes
      },
      10 * 60 * 1000,
    );

    return () => clearInterval(intervalId);
  }, [fetchQuote]);

  return (
    <QuoteContext.Provider value={quote}>{children}</QuoteContext.Provider>
  );
});

interface AppStateContextValue {
  appState: AppState;
  userSettings: UserSettings;
  updateUserSettings: (settings: Partial<UserSettings>) => void;
}

const defaultAppStateContext: AppStateContextValue = {
  appState: {
    memberStatus: { hasShares: false },
    swapStatus: { isRunning: true },
  },
  userSettings: {
    hideBalances: true,
  },
  updateUserSettings: (settings: Partial<UserSettings>) =>
    console.log(settings),
};

export const AppStateContext = createContext<AppStateContextValue>(
  defaultAppStateContext,
);

// Write provider for appstate equivalent to QuoteProvider
export const AppStateProvider = React.memo(function AppStateProvider({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element {
  const { user } = useAuth();
  const [appState, setAppState] = useState<AppState>(
    defaultAppStateContext.appState,
  );
  const [userSettings, setUserSettings] = useState<UserSettings>(
    defaultAppStateContext.userSettings,
  );

  useEffect(() => {
    let intervalId: NodeJS.Timeout;

    const checkShareHoldings = async () => {
      if (!user) return;
      try {
        const { shareHoldings } = await fetcher<
          UserShareTxsResponse,
          undefined
        >(`${BS_API_GATEWAY_URL}/shares/transactions/${user.id}`, "GET");

        if (shareHoldings > 0) {
          clearInterval(intervalId);
          setAppState({
            memberStatus: { hasShares: true },
            swapStatus: { isRunning: true },
          });
        } else {
          setAppState({
            memberStatus: { hasShares: false },
            swapStatus: { isRunning: true },
          });
        }
      } catch (e) {
        console.log(e);
      }
    };

    checkShareHoldings();

    intervalId = setInterval(
      () => {
        checkShareHoldings();
      },
      10 * 60 * 1000,
    );

    return () => clearInterval(intervalId);
  }, [user]);

  return (
    <AppStateContext.Provider
      value={{
        appState,
        userSettings,
        updateUserSettings: (settings: Partial<UserSettings>) => {
          setUserSettings({ ...userSettings, ...settings });
        },
      }}
    >
      {children}
    </AppStateContext.Provider>
  );
});

export const useApi = (): ApiContextValue => useContext(ApiContext);
export const useAuth = (): AuthContextValue => useContext(AuthContext);
export const useQuote = (): QuoteResponse | null => useContext(QuoteContext);
export const useAppState = (): AppStateContextValue =>
  useContext(AppStateContext);
