import React, { useCallback, useState } from "react";
import { Button, ButtonGroup, Flex, FormControl, Text } from "@chakra-ui/react";
import { SharesTx, SharesTxStatus, SharesTxUpdates } from "@bitsacco/types";

import { TemplateModal } from "./TemplateModal";
import { ShareInputGroup } from "../InputGroups";
import { getProfileLabel } from "../../utils";

interface DeleteShareTxModalProps {
  tx: SharesTx;
  isOpen: boolean;
  onClose: () => void;
  deleteShareTx: () => void;
}

export const DeleteShareTxModal = React.memo(function DeleteShareTxModal({
  tx,
  isOpen,
  onClose,
  deleteShareTx,
}: DeleteShareTxModalProps): JSX.Element {
  const getModalFooter = useCallback(() => {
    return (
      <ButtonGroup
        w="100%"
        display="flex"
        flexDirection="row"
        flexWrap={{ base: "wrap", lg: "nowrap" }}
        justifyContent="space-between"
        spacing={{ base: "0", lg: "4" }}
        gap={"2"}
      >
        <Button colorScheme="red" onClick={deleteShareTx}>
          Delete Transaction
        </Button>
        <Button size="lg" onClick={onClose} variant="ghost" colorScheme="red">
          Cancel
        </Button>
      </ButtonGroup>
    );
  }, [deleteShareTx, onClose]);

  return (
    <TemplateModal
      isOpen={isOpen}
      onClose={onClose}
      header={<Text>{`Update Share Transaction ${tx.id}`}</Text>}
      body={
        <Flex flexDirection="column" gap="5">
          <Text fontSize="md" color="teal.500">
            Are you sure you want to delete this transaction? {tx.id}
          </Text>
        </Flex>
      }
      footer={getModalFooter()}
    />
  );
});
