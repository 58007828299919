import React, { useEffect, useState } from "react";
import {
  Box,
  Heading,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Alert,
  AlertIcon,
  Center,
  Spinner,
  useToast,
  useDisclosure,
  Input,
  InputGroup,
  InputLeftElement,
  Text,
  Flex,
} from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { FaEllipsisVertical } from "react-icons/fa6";
import {
  SharesOffer,
  SubscribeSharesRequest,
  User,
  UserShareTxsResponse,
} from "@bitsacco/types";
import { BS_API_GATEWAY_URL, BS_API_URL } from "../configs";
import { QueryKeys } from "../enums/QueryKeys";
import { AssignShareModal, useAuth } from "../components";
import { fetcher, getProfileLabel } from "../utils";
import { FaSearch } from "react-icons/fa";

type UserInfo = User & {
  shareHoldings: number;
};

const ADMINS = [
  "dd3a83c4-9ff2-4129-98eb-44ad6612790c",
  "7b158dfd-cb98-40b1-9ed2-a13006a9f670",
];

export const MemberAdmin = () => {
  const toast = useToast();
  const { user } = useAuth();

  const [activeOffer, setActiveOffer] = useState<SharesOffer | undefined>(
    undefined,
  );

  useEffect(() => {
    if (!user) return;

    (async () => {
      try {
        const response = await fetcher<UserShareTxsResponse, undefined>(
          `${BS_API_GATEWAY_URL}/shares/transactions/${user.id}`,
          "GET",
        );

        if (response.offers) {
          setActiveOffer(
            response.offers.offers.find((offer) => {
              return offer.subscribedQuantity < offer.quantity;
            }),
          );
        }
      } catch (e) {
        console.log(e);
      }
    })();
  }, [user]);

  if (!user?.id) {
    return (
      <Center>
        <Text>You must be logged in to access this page.</Text>
      </Center>
    );
  }

  if (!ADMINS.includes(user.id)) {
    return (
      <Center>
        <Text>You are not authorized to access this page.</Text>
      </Center>
    );
  }

  const [searchTerm, setSearchTerm] = useState("");
  const [modUser, setModUser] = useState<User | undefined>(undefined);

  const {
    isOpen: showAssignShareModal,
    onOpen: onOpenAssignShareModal,
    onClose: onCloseAssignShareModal,
  } = useDisclosure();

  let {
    data: users = [],
    isLoading: fetchLoading,
    error: fetchError,
  } = useQuery<UserInfo[], Error>({
    queryKey: [QueryKeys.CHAMAS],
    queryFn: async () => {
      const ru = await fetcher<User[], Error>(`${BS_API_URL}/user/all`);

      const fu = await Promise.all(
        ru.map(async (user) => {
          try {
            const { shareHoldings } = await fetcher<
              UserShareTxsResponse,
              undefined
            >(`${BS_API_GATEWAY_URL}/shares/transactions/${user.id}`, "GET");

            return {
              ...user,
              shareHoldings,
            };
          } catch (e) {
            console.log(e);
            return {
              ...user,
              shareHoldings: 0,
            };
          }
        }),
      );

      return fu;
    },
    enabled: !!user?.id,
  });

  const filteredUsers = users.filter(
    (u) =>
      getProfileLabel(u).toLowerCase().includes(searchTerm.toLowerCase()) ||
      u.phone?.includes(searchTerm),
  );

  if (fetchLoading) {
    return (
      <Center w="100%" pt="5rem">
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="blue.500"
          size="xl"
        />
      </Center>
    );
  }

  if (fetchError) {
    return (
      <Alert status="error" mt="5rem">
        <AlertIcon />
        There was an error processing your request
      </Alert>
    );
  }

  const buyShares = (uid: string, shares: number) => {
    (async () => {
      if (shares <= 0 || !activeOffer || !uid) return;

      try {
        const response = await fetcher<
          UserShareTxsResponse,
          SubscribeSharesRequest
        >(`${BS_API_GATEWAY_URL}/shares/subscribe`, "POST", {
          userId: uid,
          offerId: activeOffer.id,
          quantity: shares,
        });

        users.map((u) => {
          if (u.id === uid) {
            u.shareHoldings = response.shareHoldings;
          }
        });

        toast({
          title: "Success",
          description: "Shares assigned successfully",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        onCloseAssignShareModal();
      } catch (error) {
        console.error(error);
        toast({
          title: "Error",
          description: "Failed to assign shares",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    })();
  };

  return (
    <Box>
      <Heading as="h1" mb={4}>
        Member Admin
      </Heading>
      <InputGroup mb={4}>
        <InputLeftElement pointerEvents="none">
          <FaSearch color="gray.300" />
        </InputLeftElement>
        <Input
          placeholder="Search by name or phone number"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </InputGroup>
      <Flex overflowX={"auto"}>
        <Table>
          <Thead>
            <Tr>
              <Th>Name</Th>
              <Th>Phone Number</Th>
              <Th>Number of Shares</Th>
              <Th>Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
            {filteredUsers.map((u) => (
              <Tr key={u.id}>
                <Td>{getProfileLabel(u)}</Td>
                <Td>{u.phone}</Td>
                <Td>{u.shareHoldings}</Td>
                <Td>
                  <Menu>
                    <MenuButton
                      as={IconButton}
                      icon={<FaEllipsisVertical />}
                      variant="outline"
                      aria-label="Options"
                    />
                    <MenuList>
                      <MenuItem
                        onClick={() => {
                          setModUser(u);
                          onOpenAssignShareModal();
                        }}
                      >
                        Assign Shares
                      </MenuItem>
                    </MenuList>
                  </Menu>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Flex>
      {modUser && (
        <AssignShareModal
          user={modUser}
          isOpen={showAssignShareModal}
          onClose={() => {
            onCloseAssignShareModal();
            setModUser(undefined);
          }}
          assignShare={(shares: number) => buyShares(modUser.id, shares)}
        />
      )}
    </Box>
  );
};
